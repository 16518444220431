<template>
  <v-card width="100%" min-height="650" elevation="0" class="pb-5 mx-auto">
    <v-data-table
      :items="getCampaigns"
      :headers="headers"
      :search="search"
      sort-by="name"
      class="elevation-1 pa-3"
      :loading="!getCampaigns.length"
      loading-text="Fetching Campaigns... Please wait"
    >
      <template slot="top">
        <v-toolbar flat>
          <v-btn color="expansionPanels" @click="fetchCampaigns()">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="90%">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              full-width
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.full_name`]="{ item }">
        {{ getFullName(item) }}
      </template>

      <template v-slot:[`item.elective_position`]="{ item }">
        {{ getElectivePstn(item) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapActions } = createNamespacedHelpers("campaigns");

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Candidate Name",
          align: "start",
          sortable: false,
          value: "full_name",
        },
        {
          text: "Elective Position",
          value: "elective_position",
        },
        {
          text: "Venue",
          value: "venue",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Start Time",
          value: "start_time",
        },
        {
          text: "End Time",
          value: "end_time",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCampaigns"]),
  },
  methods: {
    ...mapActions(["fetchCampaigns"]),

    getFullName(campaign) {
      return `${campaign.candidate.first_name} ${
        campaign.candidate.last_name
      } ${
        campaign.candidate.surname == null ? " " : campaign.candidate.surname
      }`;
    },
    getElectivePstn(item) {
      return item.candidate.elective_position;
    },
  },
};
</script>
<style></style>
