<template>
  <v-card width="100%" elevation="0" class="py-5 mx-auto">
    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-card-text>
      <v-form v-model="valid" ref="excelUploadForm">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.first_name"
                :rules="rules.genRule"
                label="Aspirant First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.last_name"
                :rules="rules.genRule"
                label="Aspirant Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.surname"
                label="Aspirant Surname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="electivePositionChoices"
                v-model="currentCampaign.elective_position"
                label="Elective Position"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :rules="rules.genRule"
                v-model.trim="currentCampaign.elective_position_area"
                label="Elective Position Area Name"
                placeholder="e.g Kaduoch North Ward, Kajiado North Constituency."
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <handy-uploader
                :documentAttachment.sync="currentCampaign.schedule"
                :fileUploaderType="'table'"
                :imageCompressor="false"
                :maxFileCount="5"
                :maxFileSize="10240"
                :badgeCounter="true"
                :cardType="'shaped'"
                :fileAccept="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                :addFileDescription="true"
                :editPermission="false"
                :thumb="true"
                :tableFixedHeader="true"
                :tableHeight="200"
                :tableWidth="400"
                :tableThumbColumn="true"
              ></handy-uploader>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="150px" color="error darken-1" text @click="close">
        Cancel
      </v-btn>
      <v-btn width="150px" color="blue darken-1" text @click="submitForm">
        Save
      </v-btn>
    </v-card-actions>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("campaigns");
import handyUploader from "handy-uploader/src/components/handyUploader";
import { handleHttpError } from "@/helpers/utils";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      rules: {
        selectRule: [(v) => !!v || "Please Select an Option"],
        genRule: [(v) => !!v || "Please fill this."],
      },
      dateModal: false,
      timeModal: false,

      autoCompleteBounds: {
        bounds: {
          north: 5.091809,
          south: -4.719315,
          east: 33.909037,
          west: 41.905454,
        },
        // strictBounds: true,
      },
      electivePositionChoices: [
        "The President",
        "Member of National Assembly",
        "Senator",
        "County Woman Member of National Assembly",
        "County Governor ",
        "Member of County Assembly ",
      ],

      deleteDialog: false,
    };
  },

  components: { handyUploader },

  props: {
    currentCampaign: {
      type: Object,
      default: () => ({
        first_name: null,
        last_name: null,
        surname: null,
        elective_position: null,
        elective_position_area: null,
        schedule: [],
      }),
    },
  },

  methods: {
    ...mapActions(["addCampaign"]),

    close() {
      this.dialog = false;
    },

    async submitForm() {
      this.$refs.excelUploadForm.validate();
      if (!this.currentCampaign.schedule.length) {
        handleHttpError(`Please attach a valid Campaign schedule.`);
        this.valid = false;
      }
      if (!this.valid) return;

      this.loading = true;
      const payload = { data: this.currentCampaign, mode: "schedule" };

      await this.addCampaign(payload);

      this.loading = false;

      // reset
      this.$refs.excelUploadForm.reset();
    },
  },
};
</script>

<style scoped>
#create {
  background-color: #66bb6a;
  color: white;
  padding: 7px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}

#venue {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
</style>
