<template>
  <v-container fluid fill-height>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- // name: autocomplete
// venue area
// date
// start_time
// end_time
// position
// ward
// county
// constituency -->

    <v-row>
      <v-col cols="12" lg="3" md="3" xl="3">
        <v-expansion-panels flat multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header> Candidate Name</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-autocomplete
                :items="candidateNames"
                cache-items
                auto-select-first
                chips
                clearable
                deletable-chips
                multiple
                small-chips
                :search-input.sync="search"
                v-model.trim="query.fullName"
                label="Candidate Name"
                @change="fetchSearch"
              ></v-autocomplete>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Venue of Campaign
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="my-1">
                <gmap-autocomplete
                  placeholder="Area Name"
                  required
                  id="venue"
                  class="v-text-field v-text-field-outlined"
                  @place_changed="setPlace"
                  :options="autoCompleteBounds"
                ></gmap-autocomplete>
              </v-row>

              <v-row class="my-1">
                <v-slider
                  v-model="query.withinAddress.radius"
                  min="200"
                  step="200"
                  max="3000"
                  @change="fetchSearch"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="query.withinAddress.radius"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      suffix="m"
                      type="number"
                      style="width: 80px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-row>

              <v-row justify="center">
                <v-btn width="100" @click="fetchSearch">Go</v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              State Date of Campaign
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-dialog
                ref="dateModal"
                v-model="dateModal"
                :return-value.sync="query.start_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.start_date"
                    label="Campaign Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="fetchSearch"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="query.start_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="dateModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.dateModal.save(query.start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              End Date of Campaign
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-dialog
                ref="end_dateModal"
                v-model="end_dateModal"
                :return-value.sync="query.end_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.end_date"
                    label="Campaign End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="fetchSearch"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="query.end_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="end_dateModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.end_dateModal.save(query.end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- <v-expansion-panel>
            <v-expansion-panel-header>
              Elective Position
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                :items="electivePositionChoices"
                v-model="query.elective_position"
                label="Elective Position"
                @change="fetchSearch"
              ></v-select>
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!-- <v-expansion-panel>
            <v-expansion-panel-header>Start Time</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-dialog
                ref="startTimeModal"
                v-model="startTimeModal"
                :return-value.sync="query.start_time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.start_time"
                    label="Campaign Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    @change="fetchSearch"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimeModal"
                  v-model="query.start_time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="startTimeModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.startTimeModal.save(query.start_time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!-- <v-expansion-panel>
            <v-expansion-panel-header>End Time</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-dialog
                ref="startTimeModal"
                v-model="startTimeModal"
                :return-value.sync="query.end_time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.end_time"
                    label="Campaign End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    @change="fetchSearch"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimeModal"
                  v-model="query.end_time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="startTimeModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.startTimeModal.save(query.end_time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-expansion-panel-content>
          </v-expansion-panel>
           -->
          <!-- <v-expansion-panel>
            <v-expansion-panel-header> Within Ward </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                :items="wards"
                v-model="query.administration_level"
                label="Elective Position"
                @change="fetchSearch"
              ></v-select>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Within Constituency
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                :items="constituencies"
                v-model="query.administration_level"
                label="Elective Position"
                @change="fetchSearch"
              ></v-select>
            </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
      </v-col>

      <v-col cols="12" lg="9" md="9" xl="9">
        <GmapMap
          ref="googleMap"
          :center="center"
          :zoom="zoom"
          class="map"
          :options="mapOptions"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <v-card
              elevation="0"
              v-if="currentCampaign"
              class="mx-auto"
              max-width="350"
            >
              <v-card-text class="features">
                <div>
                  Candidate Name:<b>{{ getFullName(currentCampaign) }}</b>
                </div>
                <div>
                  Elective Position:
                  <b>{{ currentCampaign.candidate.elective_position }}</b>
                </div>

                <div>
                  Starts At:<b>{{ currentCampaign.start_time }}</b>
                </div>
                <div>
                  Ends At:<b>{{ currentCampaign.end_time }}</b>
                </div>
              </v-card-text>
            </v-card>
          </gmap-info-window>
        </GmapMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("campaigns");

import { gmapApi } from "gmap-vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import {
  fetchConstituencies,
  fetchCounties,
  fetchWards,
} from "@/helpers/regions";
import { fetchCandidates } from "@/helpers/candidate";

export default {
  data() {
    const styles = [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#444444" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          { saturation: -100 },
          {
            lightness: 45,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#80DEEA" }, { visibility: "on" }],
      },
    ];
    return {
      map: null,
      api: null,
      apiLoaded: null,
      zoom: 7,
      center: { lat: 0.557383, lng: 37.398296 },
      loading: false,
      panel: [0],

      mapOptions: {
        zoomControl: true,
        zoomControlOptions: { position: 9 },
        mapTypeControl: true,
        mapTypeControlOptions: { position: 11 },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: { position: 9 },
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: styles,
      },

      // popup window
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWinOpen: false,

      bound: null,
      campaignsCluster: [],
      currentCampaign: null,
      search: null,
      campaigns: [],

      candidateNames: [],
      counties: [],
      wards: [],
      constituencies: [],
      electivePositionChoices: [
        "The President",
        "Member of National Assembly",
        "Senator",
        "County Woman Member of National Assembly",
        "County Governor",
        "Member of County Assembly",
      ],

      autoCompleteBounds: {
        bounds: {
          north: 5.091809,
          south: -4.719315,
          east: 33.909037,
          west: 41.905454,
        },
        // strictBounds: true,
      },
      dateModal: false,
      end_dateModal: false,
      startTimeModal: false,

      query: {
        fullName: "",
        withinAddress: { lat: 0, lng: 0, radius: 1000 },
        start_date: "",
        end_date: "",
        elective_position: "",
        start_time: "",
        end_time: "",
      },
    };
  },

  computed: {
    ...mapGetters(["getCampaigns", "getSearchResults"]),

    google: gmapApi,
  },

  watch: {
    getSearchResults: {
      handler(val) {
        if (!val) return;
        this.campaigns = val;
        this.plotCampaigns();
      },
    },
  },

  methods: {
    ...mapActions(["searchCampaigns", "fetchCampaigns"]),

    plotCampaigns() {
      this.bounds = new this.google.maps.LatLngBounds();
      this.campaignMarkers = this.campaigns.map((cmp) => {
        if (cmp.lat && cmp.lng) {
          let latlng = new this.google.maps.LatLng(
            parseFloat(cmp.lat),
            parseFloat(cmp.lng)
          );
          this.bounds.extend(latlng);

          let cmpMarker = new this.google.maps.Marker({
            position: latlng,
            icon: {
              url: "/images/location_pin_3.svg",
              scaledSize: new this.google.maps.Size(25, 25),
            },
          });

          // set listener
          this.google.maps.event.addListener(cmpMarker, "click", () => {
            this.currentCampaign = cmp;

            this.infoWindowPos = {
              lat: this.currentCampaign.lat,
              lng: this.currentCampaign.lng,
            };

            this.infoWinOpen = true;
          });
          return cmpMarker;
        }
      });

      this.campaignsCluster.clearMarkers();
      this.campaignsCluster.addMarkers(this.campaignMarkers);
      this.map.fitBounds(this.bounds);
      this.map.panToBounds(this.bounds);
    },

    getFullName(campaign) {
      return `${campaign.candidate.first_name} ${
        campaign.candidate.last_name
      } ${
        campaign.candidate.surname == null ? " " : campaign.candidate.surname
      }`;
    },

    async setPlace(place) {
      if (!place) return;
      this.query.withinAddress.lat = place.geometry.location.lat();
      this.query.withinAddress.lng = place.geometry.location.lng();
    },

    async fetchSearch() {
      this.loading = true;
      await this.searchCampaigns(this.query);
      this.loading = false;
    },

    async fetchCmps() {
      await this.fetchCampaigns();
      if (!this.getCampaigns.length) return;

      this.campaigns = this.getCampaigns;
      this.plotCampaigns();
    },
  },

  async mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write googleMap.$mapPromise.then(() => ...)
    this.map = await this.$refs.googleMap.$mapPromise;

    this.campaignsCluster = new MarkerClusterer({
      map: this.map,
      markers: [],
      averageCenter: true,
    });

    await this.fetchCmps();

    this.constituencies = await fetchConstituencies();
    this.counties = await fetchCounties();
    this.wards = await fetchWards();
    this.candidateNames = await fetchCandidates();
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 75vh;
}
</style>
