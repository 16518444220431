<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <v-card
          width="100%"
          elevation="0"
          color="#E5E5E5"
          class="d-flex pa-2 flex-column"
        >
          <v-btn
            width="180px"
            class="ma-3"
            color="expansionPanels"
            elevation="0"
            v-for="btn in buttons"
            :key="btn.name"
            @click="setComponent(btn.cmp)"
            :class="{ active: btn.cmp === currentComponent }"
            :id="btn.id"
          >
            <v-icon color="black">{{ btn.icon }}</v-icon>
            {{ btn.name }}
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card
          width="100%"
          elevation="0"
          color="#E5E5E5"
          class="d-flex pa-2 flex-column"
        >
          <keep-alive>
            <component :is="currentComponent"></component>
          </keep-alive>
        </v-card>
      </v-col>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import CampaignForm from "./CampaignForm.vue";
import CampaignHome from "@/views/campaign/CampaignHome.vue";
export default {
  data() {
    return {
      currentComponent: "CampaignHome",
      buttons: [
        {
          name: "View Campaigns",
          icon: "mdi-home",
          cmp: "CampaignHome",
        },
        {
          name: "Create Campaign",
          icon: "mdi-briefcase-upload",
          cmp: "CampaignForm",
        },
      ],
    };
  },
  components: {
    CampaignForm,
    CampaignHome,
  },
  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
};
</script>

<style scoped>
.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}
</style>
