import { handleHttpError, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchCounties() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/regions/counties`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data[0].counties;
  } catch (err) {
    const error =
      `Error fetching Counties: ${err.response.data.message}` ||
      "An error occurred fetching Counties. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchWards() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/regions/wards`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data[0].wards;
  } catch (err) {
    const error =
      `Error fetching wards: ${err.response.data.message}` ||
      "An error occurred fetching wards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchConstituencies() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/regions/constituencies`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data[0].constituencies;
  } catch (err) {
    const error =
      `Error fetching constituencies: ${err.response.data.message}` ||
      "An error occurred fetching constituencies. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchConstituencies, fetchCounties, fetchWards };
