import { handleHttpError, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchCandidates() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/candidates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data[0].candidates;
  } catch (err) {
    const error =
      `Error fetching Candidates: ${err.response.data.message}` ||
      "An error occurred fetching Candidates. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchCandidates };
