<template>
  <v-card width="100%" min-height="650" elevation="0" class="pb-5 mx-auto">
    <v-toolbar flat color="accent" class="mb-2">
      <v-spacer />
      <v-toolbar-title class="text-h5">Current Campaigns</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card elevation="0" width="100%" class="d-flex justify-end">
      <v-btn
        width="150px"
        class="ma-3"
        color="expansionPanels"
        elevation="0"
        v-for="btn in buttons"
        :key="btn.name"
        @click="setComponent(btn.cmp)"
        :class="{ active: btn.cmp === currentComponent }"
        :id="btn.id"
      >
        <v-icon color="black">{{ btn.icon }}</v-icon>
        {{ btn.name }}
      </v-btn>
    </v-card>

    <v-card width="100%" elevation="0">
      <keep-alive>
        <component :is="currentComponent"></component>
      </keep-alive>
    </v-card>
    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("campaigns");

import CampaignList from "@/components/campaign/CampaignList.vue";
import CampaignMap from "@/components/campaign/CampaignMap.vue";
export default {
  data() {
    return {
      currentComponent: "CampaignList",
      buttons: [
        {
          name: "Map",
          icon: "mdi-map-search-outline",
          cmp: "CampaignMap",
        },
        {
          name: "List",
          icon: "mdi-grid",
          cmp: "CampaignList",
        },
      ],
    };
  },

  components: { CampaignList, CampaignMap },

  methods: {
    ...mapActions(["fetchCampaigns"]),

    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
  async mounted() {
    await this.fetchCampaigns();
  },
};
</script>

<style scoped>
.active {
  background: #f68625 !important;
}
</style>
