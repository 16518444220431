<template>
  <v-card width="100%" min-height="650" elevation="0" class="py-5 mx-auto">
    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-card-text>
      <v-form v-model="valid" ref="simpleUploadForm">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.first_name"
                :rules="rules.genRule"
                label="Aspirant First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.last_name"
                :rules="rules.genRule"
                label="Aspirant Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentCampaign.surname"
                label="Aspirant Surname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="electivePositionChoices"
                v-model="currentCampaign.elective_position"
                label="Elective Position"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :rules="rules.selectRule"
                :items="areaOptions"
                cache-items
                :search-input.sync="search"
                v-model.trim="currentCampaign.elective_position_area"
                label="Elective Position Area Name"
                placeholder="e.g Kaduoch North Ward"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <gmap-autocomplete
                placeholder="Venue of Campaign"
                required
                id="venue"
                :value="currentCampaign.venue"
                class="v-text-field v-text-field-outlined"
                @place_changed="setPlace"
                :options="autoCompleteBounds"
              ></gmap-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-dialog
                ref="dateModal"
                v-model="dateModal"
                :return-value.sync="currentCampaign.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.genRule"
                    v-model="currentCampaign.date"
                    label="Campaign Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="currentCampaign.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="dateModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.dateModal.save(currentCampaign.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-dialog
                ref="startTimeModal"
                v-model="startTimeModal"
                :return-value.sync="currentCampaign.start_time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.genRule"
                    v-model="currentCampaign.start_time"
                    label="Campaign Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startTimeModal"
                  v-model="currentCampaign.start_time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="startTimeModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="
                      $refs.startTimeModal.save(currentCampaign.start_time)
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-dialog
                ref="endTimeModal"
                v-model="endTimeModal"
                :return-value.sync="currentCampaign.end_time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.genRule"
                    v-model="currentCampaign.end_time"
                    label="Campaign End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="endTimeModal"
                  v-model="currentCampaign.end_time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    width="100px"
                    text
                    color="secondary"
                    @click="endTimeModal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    width="100px"
                    text
                    color="success"
                    @click="$refs.endTimeModal.save(currentCampaign.end_time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="150px" color="error darken-1" text @click="close">
        Cancel
      </v-btn>
      <v-btn width="150px" color="blue darken-1" text @click="submitForm">
        Save
      </v-btn>
    </v-card-actions>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("campaigns");
import { handleHttpError } from "@/helpers/utils";

import {
  fetchConstituencies,
  fetchCounties,
  fetchWards,
} from "@/helpers/regions";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      rules: {
        selectRule: [(v) => !!v || "Please Select an Option"],
        genRule: [(v) => !!v || "Please fill this."],
      },
      dateModal: false,
      startTimeModal: false,
      endTimeModal: false,

      autoCompleteBounds: {
        bounds: {
          north: 5.091809,
          south: -4.719315,
          east: 33.909037,
          west: 41.905454,
        },
        // strictBounds: true,
      },

      electivePositionChoices: [
        "The President",
        "Member of National Assembly",
        "Senator",
        "County Woman Member of National Assembly",
        "County Governor",
        "Member of County Assembly",
      ],

      deleteDialog: false,
      counties: [],
      wards: [],
      constituencies: [],
      areaOptions: [],
      search: null,

      currentCampaign: {
        first_name: null,
        last_name: null,
        surname: null,
        venue: null,
        lat: null,
        lng: null,
        elective_position: null,
        elective_position_area: null,
        date: null,
        start_time: null,
        end_time: null,
      },
    };
  },

  watch: {
    currentCampaign: {
      deep: true,
      handler(val) {
        if (val.elective_position === "The President") {
          this.areaOptions = ["Kenya"];
        } else if (val.elective_position === "Member of County Assembly") {
          this.areaOptions = this.wards;
        } else if (val.elective_position === "Senator") {
          this.areaOptions = this.counties;
        } else if (
          val.elective_position === "County Woman Member of National Assembly"
        ) {
          this.areaOptions = this.counties;
        } else if (val.elective_position === "County Governor") {
          this.areaOptions = this.counties;
        } else if (val.elective_position === "Member of National Assembly") {
          this.areaOptions = this.constituencies;
        }
      },
    },
  },

  methods: {
    ...mapActions(["addCampaign"]),

    async setPlace(place) {
      if (!place) return;
      this.currentCampaign.venue = place.formatted_address;
      this.currentCampaign.lat = place.geometry.location.lat();
      this.currentCampaign.lng = place.geometry.location.lng();
    },

    close() {
      this.dialog = false;
    },

    async submitForm() {
      this.$refs.simpleUploadForm.validate();
      if (!this.currentCampaign.venue) {
        handleHttpError(`A Campaign must have a venue.`);
        this.valid = false;
      }
      if (!this.valid) return;

      this.loading = true;
      const payload = { data: this.currentCampaign };

      await this.addCampaign(payload);

      this.loading = false;

      // reset
      this.$refs.simpleUploadForm.reset();
    },
  },

  async mounted() {
    this.constituencies = await fetchConstituencies();
    this.counties = await fetchCounties();
    this.wards = await fetchWards();
  },
};
</script>

<style scoped>
#create {
  background-color: #66bb6a;
  color: white;
  padding: 7px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}

#venue {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
</style>
